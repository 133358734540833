
import { Options, Vue } from 'vue-class-component';
import { AdminClient, ChallengeClient, GameClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import InfoButton from '../components/infoButton.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
        InfoButton
    }
})
export default class Challenge extends Vue {

    selectedLanguage: string;
    availableLanguages: OM.TextIdentifier[] = [];
    loggedUser: OM.LoggedUserVM = new OM.LoggedUserVM();

    challengeCompleted: boolean = false;
    stages: OM.StageVM[] = [];
    gameResume: OM.UserGameResume = new OM.UserGameResume();

    isPublished: boolean = false;
    
    created() {
        this.selectedLanguage = store.state.loggedUser.preferredCulture;
        this.availableLanguages = this.$localizationService.getEnabledLanguageList();
        this.loggedUser = StorageServices.getLoggedUser();

        AdminClient.getChallengeStatus()
        .then(x => {
            this.isPublished = x;

            if(this.isPublished) {
                GameClient.getUserGameResume()
                .then(x => {
                    this.gameResume = x;

                    if(x && x.completedOn) {
                        this.gameResume.identifier = x.identifier;
                        this.challengeCompleted = true;
                    }
                    else {
                        ChallengeClient.getAllStagesInfo()
                        .then(x => {
                            this.stages = x;
                        })
                    }
                })
            }
        })
    } 

    get fanLink() {
        if(this.selectedLanguage == "it-IT")
            return "https://bearacerclub.aprilia.com/il-tuo-profilo-fan/";
        else
            return "https://bearacerclub.aprilia.com/en/your-fan-profile/";
    }

    checkIfFound(stageId: string) {
        if(!this.gameResume)
            return false;

        return this.gameResume.stagesDone.find(x => x.stageIdentifier == stageId);
    }

    logout() {
        this.$router.replace("/");
    }

}
